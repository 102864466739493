<template>
  <v-dialog
    :value="value"
    persistent
    transition="dialog-bottom-transition"
    fullscreen
  >
    <v-card>
      <v-card-title>
        <div
          class="d-flex align-center justify-space-between"
          style="width: 100%;"
        >
          <span>
            A/S {{ `${cancelSignData && cancelSignData.workorderState === '4' ? '취소' : '서명'}` | translate }}
          </span>
          <v-icon
            class="mr-2"
            @click.stop="$emit('input', false)"
          >
            mdi-close
          </v-icon>
        </div>
      </v-card-title>
      <v-card-text
        style="padding: 0"
      >
        <v-textarea
          v-if="!!cancelSignData && cancelSignData.workorderState === '4'"
          ref="asContentTextarea"
          v-model="asContent"
          :label="'취소사유' | translate"
          hide-details
          outlined
        />
        <div
          v-if="!!cancelSignData && cancelSignData.workorderState === '5'"
        >
          <div
            ref="signaturePadContainer"
            style="width: 100%; height: 100%; min-height: 400px; max-height: 400px; background-color: rgba(245, 245,245, 0.7)"
          >
            <VueSignaturePad
              ref="signaturePad"
              :option="padOptions"
            />
          </div>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn
          class="mt-4"
          color="primary"
          style="width: 100%"
          @click.stop="saveCancelOrSign"
        >
          {{ '요청' | translate }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'ExternalAsCancelOrSign',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    cancelSignData: {
      type: Object,
      default: undefined,
    }
  },
  data() {
    return {
      padOptions: {
        penColor: "rgb(0, 0, 0)",
        backgroundColor: "rgb(255,255,255)"
      },
      mounting: false,
      mount: false,

      asContent: undefined,
    }
  },
  watch: {
    cancelSignData:{
      immediate: true,
      deep: true,
      handler(newVal) {
        if (newVal.workorderState === '5') {
          this.asContent = undefined
          setTimeout(() => {
            this.mounting = false;
            this.$nextTick(() => {
              if (this.mount) this.resizePad();
            })
          }, 600);
        }
      }
    }
  },
  created() {
    this.mounting = true;
  },
  mounted() {
    this.mount = true;
  },
  methods: {


    resizePad() {
      this.$nextTick(() => {
        const container = this.$refs.signaturePadContainer;
        if (!container) {
          console.error("signaturePadContainer is undefined");
          return;
        }
        const canvas = this.$refs.signaturePad.$el.querySelector('canvas');
        if (!canvas) {
          console.error("canvas is undefined");
          return;
        }
        canvas.setAttribute('width', container.clientWidth);
        canvas.setAttribute('height', container.clientHeight);
      })
    },

    clearPad() {
      this.$refs.signaturePad.clearSignature();
    },

    savePad() {
      const {isEmpty, data} = this.$refs.signaturePad.saveSignature();
      return isEmpty ? null : data;
    },
    saveCancelOrSign() {
      this.$emit('save-cancel-or-sign', {
        asContent: this.asContent,
        signImage: this.cancelSignData.workorderState === '5' ? this.savePad() : undefined
      })
    }
  },
}
</script>
<style scoped>

</style>