<template>
  <div
    style="position: relative; height: 100%"
  >
    <div class="header">
      <div style="flex: 1; display: flex; align-items: center">
        <v-btn
          class="more-menu-items-con"
          elevation="text"
          color="white"
          @click="$router.back()"
        >
          <v-icon class="mr-2">
            mdi-chevron-left
          </v-icon>
        </v-btn>
        <span class="title">
          {{ 'A/S 조회' | translate }}
        </span>
      </div>
      <v-btn
        class="more-menu-items-con"
        elevation="text"
        color="white"
        @click="retrieveAsList"
      >
        <v-icon class="mr-2">
          mdi-refresh
        </v-icon>
      </v-btn>
    </div>
    <div class="content-wrap">
      <div
        v-for="item in items"
        :key="`as-list-${item.workorderId}`"
        class="as-item-wrap"
        @click.stop="onClickItem(item)"
      >
        <div>
          <span>{{ item.accountName }} {{ params.assetItem.empYn === 'Y' ? `[${item.accountId}]` : '' }}</span>
          <span
            class="color__blue"
            style="min-width: 120px"
          >
            <template v-if="item.register === 'CUSTOMER'">
              [{{ '고객요청' | translate }}]
            </template>
            {{ item.workorderStateName }}
          </span>
        </div>
        <div>
          {{ item.address }} {{ item.addressDetail }}
        </div>
        <div>{{ item.printerModelName }}</div>
        <div>
          {{ 'A/S 기사' | translate }}: {{ item.userFullname }}
        </div>
        <div>
          {{ '접수일시' | translate }}: {{ item.workorderReceiptDate | datetime }}
        </div>
        <div>
          {{ '예약일시' | translate }}: {{ item.reservationDatetime | datetime }}
        </div>
        <div>
          <template v-if="['4', '5'].includes(item.workorderState + '')">
            {{ `${ item.workorderState + '' === '5' ? '완료' : '취소'}일시` | translate }}: {{ item.doneDate | datetime }}
          </template>
        </div>
        <div
          class="d-flex"
          style="width: 100%;"
          @click.stop
        >
          <v-btn
            class="more-menu-items-con"
            style="flex: 1"
            elevation="text"
            color="primary"
            @click="onCancelOrSign(item, '5')"
          >
            <v-icon class="mr-2">
              mdi-draw-pen
            </v-icon>
            {{ '완료서명' | translate }}
          </v-btn>
          <spacer class="ma-2" />
          <v-btn
            class="more-menu-items-con"
            style="flex: 1"
            elevation="text"
            color="error"
            @click="onCancelOrSign(item, '4')"
          >
            <v-icon class="mr-2">
              mdi-account-cancel
            </v-icon>
            {{ '취소요청' | translate }}
          </v-btn>
        </div>
      </div>
      <div
        v-if="items.length === 0"
        class="mt-10 d-flex align-center justify-center"
      >
        <span>{{ '데이터를 조회할 수 없습니다.' | translate }}</span>
      </div>
    </div>
    <v-dialog
      v-model="workorderContentsDialog"
      max-width="340px"
      persistent
      transition="dialog-bottom-transition"
      fullscreen
    >
      <v-card>
        <v-card-title>
          <div
            class="d-flex align-center justify-space-between"
            style="width: 100%;"
          >
            <span>
              {{ 'A/S 접수내용' | translate }}
            </span>
            <v-icon
              class="mr-2"
              @click.stop="onCloseContent"
            >
              mdi-close
            </v-icon>
          </div>
        </v-card-title>
        <v-card-text style="padding: 0">
          <m-form
            ref="form"
            :edit="false"
            :form-data.sync="selectedItem"
            :form-info="formInfo"
            class="overflow-y-auto overflow-x-hidden"
            form-provider="mps"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <!--    v-if="cancelSignDialog"-->
    <external-as-cancel-or-sign
      v-if="cancelSignDialog"
      v-model="cancelSignDialog"
      :cancel-sign-data="cancelSignData"
      @save-cancel-or-sign="(e) => saveCancelOrSign(e)"
      @on-close-cancel-or-sign="onCloseCancelOrSign"
    />
  </div>
</template>

<script>
import VuexProps from "@/framework/vuex/VuexProps";
import {MAXLENGTH, PATH} from "@/constants";
import {ETC} from "@/constants/etc-code";
import DetailUtil from "@/components/mixins/DetailUtil.vue";
import {cloneDeep} from "lodash";
import AsMultiFileUploadMixin from "@/components/windows/as/mixins/AsMultiFileUploadMixin.vue";
import ExternalAsCancelOrSign from "@/components/external/qr-asset/ExternalAsCancelOrSign.vue";
import RequesterBuilder from "@/assets/plugins/mps-common/network/requester-builder";
import Spacer from "@/assets/plugins/mps-form/providers/components/util/Spacer.vue";

const QR_AS_PW = 'QR-AS-PW'

export default {
  name: "QrAssetAsList",
  components: {Spacer, ExternalAsCancelOrSign},
  mixins: [VuexProps, DetailUtil, AsMultiFileUploadMixin],
  data() {
    return {
      loading: false,
      asList: [],
      selectedItem: undefined,
      workorderContentsDialog: false,
      formInfo: [
        {
          mutable: true,
          title: this.$translate('증상 상세'),
          key: 'workorderContent',
          formReadType: this.$mps.form.types.FILE_UPLOAD,
          formWriteType: this.$mps.form.types.FILE_UPLOAD,
          updateData: (v) => {
            this.selectedItem.workorderContentAttachments = v.attachments;
          },
          options: {
            denseMode: true,
            listType: ETC.MULTIFILE_FORM_LIST_TYPE.IMAGE_TYPE,
            accept: ETC.ACCEPT.IMAGE,
            row: 5,
          },
        },
      ],
      cancelSignDialog: false,
      cancelSignData: undefined,
      padOptions: {
        penColor: 'rgb(0, 0, 0)',
        backgroundColor: 'rgb(255,255,255)'
      },
      mounting: false,
      mount: false,
    }
  },
  computed: {
    params() {
      return this.$route.params
    },

    items() {
      try {
        return this.asList.map(e => {
          let {workorderState, accountName, ...others} = e
          let workorderStateName
          switch (workorderState) {
            case '1':
            case 1:
              workorderStateName = '접수'
              break
            case '2':
            case 2:
              workorderStateName = '기사대기'
              break
            case '3':
            case 3:
              workorderStateName = '진행'
              break
            case '4':
            case 4:
              workorderStateName = '취소'
              break
            case '5':
            case 5:
              workorderStateName = '완료'
              break
            case '6':
            case 6:
              workorderStateName = '보류'
              break
          }
          if (this.params.assetItem.empYn !== 'Y') {
            accountName = this.maskDisplayAccountName(accountName)
          }
          return {
            workorderStateName,
            workorderState,
            accountName,
            ...others,
          }
        })
      } catch (e) {
        return []
      }
    }
  },
  watch: {},
  created() {
  },
  mounted() {
    if (!this.params || Object.keys(this.params).length === 0 || this.params === '') {
      alert('비정상적인 접근입니다.')
      this.$router.back()
    } else {
      this.retrieveAsList()
    }
  },
  methods: {
    /**
     * A/S 조회
     */
    async retrieveAsList() {
      if (this.loading) {
        this.$alert('처리중인 요청이 있습니다..')
        return
      }
      if (this.params.assetItem.empYn !== 'Y' && (!this.params.asPassword || this.params.asPassword.trim().length !== 4)) {
        this.$alert('확인용 비밀번호 4자리를 입력해 주세요.')
        return
      }
      this.loading = true
      this.asList.splice(0, this.asList.length)

      let isBack = false
      try {
        const result = await this.$request(PATH.EXTERNAL.RETRIEVE_QR_ASSET_AS_LIST)
          .setObject({
            asPassword: this.params.asPassword,
            ...this.params.assetItem
          })
          .catch()
          .enqueue()
        if (result && result.data && result.data.length > 0) {
          localStorage.setItem(QR_AS_PW,this.params.asPassword)
          this.asList.push(...result.data)
        } else {
          localStorage.removeItem(QR_AS_PW)
          this.$alert('조회된 A/S 가 없습니다. 조회 비밀번호를 확인해 주세요.')
          isBack = true
        }
      } catch (e) {
        console.error(e)
        this.$alert('서버에서 에러가 발생하였습니다.')
        isBack = true
      } finally {
        this.loading = false
        if (isBack) {
          this.$router.back()
        }
      }
    },


    /**
     * 거래처 명 일부를 블라인드 처리
     * @param orgStr
     * @return {*|string}
     */
    maskDisplayAccountName(orgStr) {
      if (!orgStr) {
        return undefined
      }
      // 입력받은 문자열의 길이를 계산
      const length = orgStr.length;

      if (length >= 4) {
        // 네 글자 이상인 경우
        const firstTwo = orgStr.slice(0, 2);
        const lastOne = orgStr.slice(-1);
        const maskedMiddle = '●'.repeat(length - 3);
        return `${firstTwo}${maskedMiddle}${lastOne}`;
      } else if (length >= 2) {
        const allButLast = orgStr.slice(0, -1);
        return `${allButLast}●`;
      } else {
        return orgStr;
      }
    },

    /**
     * 항목 선택
     * @param item
     */
    onClickItem(item) {
      this.selectedItem = cloneDeep(item)
      this.loadDetail()
    },

    async loadDetail() {
      try {
        this.selectedItem['workorderContentAttachments'] = []
        const result = await this.$request(PATH.EXTERNAL.RETRIEVE_QR_ASSET_AS_DETAIL)
          .setObject(this.selectedItem.workorderId)
          .catch()
          .enqueue()
        if (!!result && !!result.data && result.data.length > 0)
        this.selectedItem.workorderContentAttachments.push(...result.data)
        try {
          this.toAttachments(this.selectedItem); // 파일 정보를 적절한 모델로 변환
        } catch (e) {
          console.error(e);
          this.$alert(this.$translate("첨부파일을 가져오지 못했습니다."));
        }
        try {
          // 접수증상 상세
          this.updateOptionByKey('workorderContent', () => {
            return {
              attachments: this.attachments.workorderContentAttachments,
              listType: ETC.MULTIFILE_FORM_LIST_TYPE.IMAGE_TYPE,
              accept: ETC.ACCEPT.IMAGE,
            }
          });
        } catch (e) {
          console.error(e);
          this.$snackbarError(this.$translate("옵션 초기화에 실패하였습니다."));
        }
      } catch (e) {
        this.$alert('서버에서 에러가 발생하였습니다.')
        return
      }

      this.workorderContentsDialog = true
    },

    onCloseContent() {
      this.selectedItem = undefined
      this.workorderContentsDialog = false
    },

    onCancelOrSign(item, state) {
      if (['4', '5'].includes(item.workorderState + '')){
        this.$alert('[완료] 또는 [취소] 처리된 A/S는 요청할 수 없습니다.')
        return
      }
      this.cancelSignData = cloneDeep(item)
      this.cancelSignData.workorderState = state
      this.cancelSignDialog = true
    },

    onCloseCancelOrSign() {
      this.cancelSignData = undefined
      this.cancelSignDialog = false
    },

    async saveCancelOrSign(e) {
      if (this.cancelSignData.workorderState === '5' && !e.signImage) {
        this.$alert('서명을 확인해주세요.')
        return
      } else if (this.cancelSignData.workorderState === '4' && !e.asContent){
        this.$alert('취소사유를 입력해주세요.')
        return
      }
      let {doneDate, ...others} = this.cancelSignData
      const context = {
        register: 'CUSTOMER',
        asContent: e.asContent,
        doneDate: this.$now().toISO(),
        prevWorkorderState: this.items.find(e => e.workorderId === this.cancelSignData.workorderId).workorderState,
        ...others
      }
      const signImageName = "signpad-" + this.cancelSignData.workorderId + "-" + this.cancelSignData.asId + ".png";
      try {
        const response = await this.$requestWithOverlay(PATH.EXTERNAL.RETRIEVE_QR_ASSET_AS_PROCESS)
          .multipart()
          .appendObject("context", context)
          .appendDataUri("signImage", e.signImage, signImageName)
          .enqueue();
        if (response) {
          this.$alert('A/S 변경 요청을 성공했습니다.')
          this.onCloseCancelOrSign()
          this.retrieveAsList()
        }
      } catch (e) {
        console.error(e)
        this.$alert('요청을 실패하였습니다.')
      }
    },
  }
}
</script>

<style scoped>
.header {
  position: relative;
  height: 56px;
  transform: translateY(0px);
  padding: 16px 0 !important;
  max-height: 56px !important;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: start;
  background: #ffffff;
  border-bottom: 1px solid #eeeeee;
  z-index: 1;
}

.header .title {
  font-size: 20px;
  font-weight: 700 !important;
}

.more-menu-items {
  background: #ffffff;
  padding: 8px;
}

.more-menu-items-con {
  display: flex;
  align-items: center;
  justify-content: start;
  margin: 10px 0;
  text-align: left;
}

.content-wrap {
  height: calc(100% - 57px);
  overflow-y: auto;
}

.as-item-wrap {
  padding: 14px 18px;
  border-bottom: 1px solid #eee;
  text-align: left;
  position: relative;
}

.as-item-wrap:nth-child(2n) {
  background: #fafafa;
}

.as-item-wrap div:nth-child(1) {
  font-size: 16px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 8px;
}

.as-item-wrap div:nth-child(2) {
  font-size: 17px;
  font-weight: bold;
}

.as-item-wrap div:nth-child(3),
.as-item-wrap div:nth-child(4),
.as-item-wrap div:nth-child(5),
.as-item-wrap div:nth-child(6),
.as-item-wrap div:nth-child(7) {
  font-size: 14px;
  color: #666666;
  min-height: 22px;
}
.item-more-btn {
  position: absolute;
  right: 16px;
  bottom: 8px;
}



.signature-pad {
  position: relative;
  margin-top: 5px;
}

.signature-pad::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(230, 230, 230, 0.3);
  pointer-events: none;
}
</style>
